import React from "react";

export const Products = (props) => {
  return (
    <div id="products" className="text-center">
      <div className="container" id="products-section-container">
        <div className="section-title">
          <h2>Nasze produkty</h2>
          <p>
          Wszytkie nasze produkty wykonujemy ze stali węglowej, stali nierdzewnej lub aluminium. Masz również możliwość zadecydowania o wypełnieniu czy innych delatach.
          </p>
        </div>
        <div className="products-table-container">
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <img src={d.image} alt={d.altName} className="product-img"/>
                    <div className="product-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};
